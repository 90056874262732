import { useEffect, useState } from 'react';
import { db } from "../../firebase";
import { doc, getDoc, updateDoc, serverTimestamp, query, where, collection, getDocs, onSnapshot } from "firebase/firestore";
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import SettingsIcon from '@mui/icons-material/Settings';
import CallEndIcon from '@mui/icons-material/CallEnd';
import { Typography } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebase';

const VideoToggle = (props) => {

    const [patientInfo, setPatientInfo] = useState()

    useEffect(() => {

            const q = query(collection(db, "Patients"), where("roomName", "==", props.meetingInfo?.roomName));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const data = querySnapshot.docs.map(doc => doc.data());
                setPatientInfo(data);
            });
            return () => unsubscribe();
    }, [])

    useEffect(() => {
        if(patientInfo) {
            console.log("Patient info ::: ", patientInfo[0]?.paymentIdVipps)
        }
    }, [patientInfo])


    const [videoToggle, setVideoToggle] = useState(true); 

    const [toggleColorPinfo, setToggleColorPinfo] = useState(true); 
    const [toggleColorMic, setToggleColorMic] = useState(true); 
    const [toggleColorVid, setToggleColorVid] = useState(true); 

    const [microphoneToggle, setMicrophoneToggle] = useState(true);
    const [showPatientInfo, setShowPatientInfo] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);


    const useStyles = makeStyles(theme => ({
        funcButtons: {
          "&:hover, &.Mui-focusVisible": { backgroundColor: "#9E9E9E" },
          backgroundColor : 'white'
        },
        funcButtonsActive: {
            "&:hover, &.Mui-focusVisible": { backgroundColor: "#DD0A01" },
            backgroundColor : '#DD0A01'
          },
        hangUp: {
            "&:hover, &.Mui-focusVisible": { backgroundColor: "#9A2521" },
            backgroundColor : '#DD0A01;'
        },
      }));
      
    const classes = useStyles();

    const cancelPayment = (id, reference, merchant) => {
        fetch(`https://us-central1-wifi-87474.cloudfunctions.net/vippsCancelPayment`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                "orderId": patientInfo[0]?.paymentIdVipps,
            })
        })
        .then(response => response.json())
        .then((response) => {
            endConsultation();
            console.log(response)
        })
        .catch(error => console.error(error));
    }

    const approvePayment = async () => {
        try {
            console.log("Approving payment for orderId:", patientInfo[0]?.paymentIdVipps);
            
            const vippsApprove = httpsCallable(functions, 'vippsApprovePaymentCall');
            const result = await vippsApprove({
                orderId: patientInfo[0]?.paymentIdVipps
            });
            
            console.log('Payment approved:', result.data);
            
            await endConsultation();
        } catch (error) {
            console.error('Failed to approve payment:', error);
            alert('Kunne ikke godkjenne betaling. Vennligst prøv igjen.');
        }
    };

    const endConsultation = async () => {
        props.setEndConsultation(true);
        window.onbeforeunload = function () {
            // blank function do nothing
          } 
        const q = query(collection(db, "Patients"), where("roomName", "==", props.meetingInfo?.roomName));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((document) => {
            // doc.data() is never undefined for query doc snapshots
        
            const userRef = doc(db, "Patients", document.id);
            setTimeout(() => {
                updateDoc(userRef, {
                    active: false,
                    treatmentEndedDate: serverTimestamp()
                    }).then(async (result) => {
                        props.leaveCall();
                        window.location.href = "/";
                    }).catch((err) => {
                        console.log("Error på DB update ::: ", err)
                    });
            }, 200)

          });


          /* updateDoc(userRef, {
            active: false,
            treatmentEndedDate: serverTimestamp()
            }).then(async (result) => {
                console.log("Done n done ::: ", result)
            }).catch((err) => {
                console.log("Error på DB update ::: ", err)
            });
            props.leaveCall() */

    }

    useEffect(() => {
        console.log("Tester tester ::: ", props.user)
    }, [])
  
    return (
        <>
            {showPatientInfo && (
                <>
                    <style>{"\
                        .patient-info{\
                            display: block !important;\
                            opacity: 1 !important;\
                            pointer-events: all !important;\
                        }\
                    "}</style>
                </>
            )}

            <Stack direction="row" spacing={3}>
                {props?.user?.type === "Doctor" && (
                    <>
                    <Tooltip title={<Typography fontSize={13}>Pasientinformasjon</Typography>} placement="top">
                        <IconButton onClick={() => {
                            setShowPatientInfo(!showPatientInfo);
                            setToggleColorPinfo(!toggleColorPinfo);
                        }} className={toggleColorPinfo ? classes.funcButtons : classes.funcButtonsActive} aria-label="Pasientinformasjon" size="large">
                            {showPatientInfo && <FileOpenIcon sx={{ color: '#fff' }} />}
                            {!showPatientInfo && <FileOpenIcon sx={{ color: '#2C3748' }} />}
                        </IconButton>
                    </Tooltip>
                    </>
                    )
                }
                <Tooltip title={<Typography fontSize={13}>Slå {!microphoneToggle ? 'på' : 'av'} mikrofon</Typography>} placement="top">
                    <IconButton 
                        onClick={() => {
                            microphoneToggle ? props.audioOff() : props.audioOn();
                            setMicrophoneToggle(!microphoneToggle);
                            setToggleColorMic(!toggleColorMic);
                        }} 
                        className={toggleColorMic ? classes.funcButtons : classes.funcButtonsActive} aria-label="Mikrofon" size="large"
                    >
                        {!microphoneToggle && (
                            <>
                                <MicOffIcon sx={{ color: '#fff' }} />
                            </>
                        )}
                        {microphoneToggle && (
                            <>
                                <MicIcon sx={{ color: '#2C3748' }} />
                            </>
                        )}
                    </IconButton>
                </Tooltip>

                <Tooltip title={<Typography fontSize={13}>Slå {!videoToggle ? 'på' : 'av'} video</Typography>} placement="top">
                    <IconButton 
                        onClick={() => {
                            videoToggle ? props.videoOff() : props.videoOn();
                            setVideoToggle(!videoToggle);
                            setToggleColorVid(!toggleColorVid);
                        }} 
                        className={toggleColorVid ? classes.funcButtons : classes.funcButtonsActive} aria-label="Video" size="large"
                    >
                        {!videoToggle && (
                            <>
                                <VideocamOffIcon sx={{ color: '#fff' }} />
                            </>
                        )}
                        {videoToggle && (
                            <>
                                <VideocamIcon sx={{ color: '#2C3748' }} />
                            </>
                        )}
                    </IconButton>
                </Tooltip>

                {/*
                    <Tooltip title={<Typography fontSize={13}>Instillinger</Typography>} placement="top">
                        <IconButton className={classes.funcButtons} aria-label="Innstillinger" size="large">
                            <SettingsIcon sx={{ color: '#2C3748' }} />
                        </IconButton>
                    </Tooltip>
                */}

                <Tooltip title={<Typography fontSize={13}>Avslutt samtale</Typography>} placement="top">
                    <IconButton  
                        onClick={() => props.user.type === "Patient" ? props.leaveCall() : setShowConfirm(true)}
                        sx={{marginLeft: '35px !important' }} 
                        className={classes.hangUp} aria-label="Avslutt samtale" size="large">
                        <CallEndIcon sx={{ color: 'white' }} />
                    </IconButton>
                </Tooltip>

            </Stack>
            {showConfirm &&
  
                    <div className="aktiv-behandler-wrapper" style={{position: 'fixed'}}>
                      <div className="inner">
                          <div>
                              <CancelPresentationIcon />
                              <h2 style={{marginBottom: '15px'}}>Er du sikker på at du vil avslutte behandlingen?</h2>
                              <Button 
                                sx={{background: '#2C3748 !important', color: 'white', ml: 0, borderColor: 'transparent'}}
                                variant="outlined" onClick={() => {
                                    setShowConfirm(!showConfirm);
                                    }}>
                                    Fortsett behandling
                              </Button>
                            <div className="borders-divide" style={{marginBottom: '15px'}}></div>
                              <Button variant="outlined" sx={{ mr: 1, mb: 1, color: 'white'}}  onClick={() => {
                                approvePayment();
                                }}>
                                  Avslutt behandling
                              </Button>
                              <Button variant="outlined" sx={{ mb: 1, color: 'white'}} onClick={() => {
                                cancelPayment();
                                }}>
                                  Refunder penger og avslutt
                              </Button>

                          </div>
                      </div>
                  </div>
            }
        </>
    )
}

export default VideoToggle;