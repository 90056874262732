import * as React from "react";
import SetDoctorInformation from "../components/Doctor/setDoctorInformation";
import WaitingForApproval from "../components/Doctor/waitingForApproval";
import MainDoctorPage from "../components/Doctor/mainDoctorPage";

const DoctorPage = () => {

    return (
        <>
            <SetDoctorInformation />
            <WaitingForApproval />
            <MainDoctorPage />
        </>
    )

}

export default DoctorPage