import { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { db } from "../../firebase";
import { collection, query, where, doc, updateDoc, setDoc, addDoc, onSnapshot, serverTimestamp } from "firebase/firestore";
import {useNavigate} from 'react-router-dom';
import VideocamIcon from '@mui/icons-material/Videocam';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Button from '@mui/material/Button';

const ActiveCOnsultations = (props) => {
    const navigate = useNavigate();
    const [user, setUser, userDb, setUserDb, meetingInfo, setMeetingInfo] = useOutletContext();
    const [activeConsultations, setActiveConsultations] = useState();
    const [joinRoom, setJoinRoom] = useState({})

    useEffect(() => {
        const q = query(collection(db, "Patients"), where("active", "==", true), where("payed", "==", true), where("patientId", "==", user?.uid));
        // Quer to use if you want to keep patients in list, even if they are gettingServed.
        //const q = query(collection(db, "Patients"), where("active", "==", true));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const data = querySnapshot.docs.map(doc => doc);
   
            setActiveConsultations(data);
        });
        return () => unsubscribe();
    }, [])

        useEffect(() => {
            if(joinRoom?.type) {
              setMeetingInfo(joinRoom);
              return navigate("/meeting");
            }
        }, [joinRoom])

        
    if(!activeConsultations) {
        return (
            <>
            </>
        )
    }

    return (
        <>
        {activeConsultations.map((data, index) => {
            console.log("Data fra aktive konsultasjoner ::: ", data.data())
            if(data.data().gettingServed) {
                return (
                    <>
                        <div className="aktiv-behandler-wrapper">
                            <div className="inner">
                                <div >
                                    <VideocamIcon />
                                    <h2>Du har en aktiv behandling</h2>
                                    <p>Det ser ut til at du har en aktiv behandling. Hvis du mener dette er feil, kontakt oss på <a href="mailto:mail@mail.no">mail@mail.no</a></p>
                                    <Button variant="contained"  onClick={() => {setJoinRoom({type: "patient", roomName: data.data()?.roomName})}}>
                                        Fortsett behandling
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </>
                )
                
            }
            return (
                <>
                    <div className="aktiv-behandler-wrapper">
                        <div className="inner">
                            <div >
                                <AccessTimeIcon />
                                <h2>Venter på lege</h2>
                                <p>En lege vil så snart som mulig starte din behandling. Du mottar en SMS så snart legen er klar</p>
                            </div>
                        </div>
                    </div>
                </>
            )
        })}
        </>
    )
}

export default ActiveCOnsultations
