import { useOutletContext } from "react-router-dom";
import ApproveDoctors from "../components/admin/approveDoctors";
import PaymentHistory from "../components/admin/paymentHistory";
import Statisitc from '../components/admin/statisitc';
import {useState } from "react";
import FourOfour from "../pages/404"
import { Button } from "@mui/material";
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import PaymentIcon from '@mui/icons-material/Payment';
import BarChartIcon from '@mui/icons-material/BarChart';

const Administrator = () => {
    const [menuToggle, setMenuToggle] = useState({
        consultation: true,
        support: false,
        myPage: false,
        open: false,
    });
    const [user, setUser, userDb, setUserDb, meetingInfo, setMeetingInfo] = useOutletContext();
    

    if(user && userDb?.superUser === true) {
        return (
            <>
            <style>
                {"\
                footer { \
                    display: none !important; \
                }\
                "}
            </style>
            <style>
                {"\
                header .container { \
                    max-width: unset !important;\
                    padding: 0 20px !important;\
                }\
                "}
            </style>
            <style>
                {"\
                @media screen and (max-width: 992px) {\
                    header .container { \
                        padding: 0 0px !important;\
                    }\
                }\
                "}
            </style>
            <style>
                {"\
                header { \
                    box-shadow: none !important;\
                }\
                "}
            </style>
                <div className="hovedwrapper" style={{minHeight: '100vh'}}>
                    <div className={menuToggle.open ? 'sidebar mob-open' : 'sidebar'} style={{height: 'auto', minHeight: '100vh'}}>
                        <div className="menu">
                            <ul>
                                <li>
                                    <a  
                                        className={menuToggle.consultation ? 'active' : ''}
                                        onClick={() => setMenuToggle({...menuToggle, consultation: true, support: false, myPage: false, open: false})}
                                    >
                                        <LocalHospitalIcon />
                                        <span>Leger til godkjenning</span>
                                    </a>
                                </li>
                                {/*<li>
                                    <a
                                        className={menuToggle.myPage ? 'active' : ''}
                                        onClick={() => setMenuToggle({...menuToggle, consultation: false, support: false, myPage: true, open: false})}
                                    >
                                        <PaymentIcon />
                                        <span>Bestlings historikk</span>
                                    </a>
                                </li> */}
                                <li>
                                    <a
                                        className={menuToggle.support ? 'active' : ''}
                                        onClick={() => setMenuToggle({...menuToggle, consultation: false, support: true, myPage: false, open: false})}
                                    >
                                        <BarChartIcon />
                                        <span>Statistikk</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="content" style={{minHeight: '100vh'}}>
                        <div className="inner-content" style={{height: '100%', minHeight: '100vh'}}>
                            <div onClick={() => setMenuToggle({...menuToggle, open: false})} className={menuToggle.open ? 'overlay open' : 'overlay'}></div>
                            <Button
                                className="menu-btn"
                                onClick={() => setMenuToggle({...menuToggle, open: true})}
                                sx={{backgroundColor: '#d2d2d2', marginBottom: '15px'}}
                                    variant="cotnained"
                                >
                                    <MenuOpenIcon sx={{marginRight: '5px'}} />
                                    MENY
                            </Button>
                            {menuToggle.consultation && <ApproveDoctors user={userDb} /> }
                            {/* menuToggle.myPage && <PaymentHistory /> */}
                            {menuToggle.support && (
                                <>
                                    <Statisitc />
                                </>
                            )}
                        </div>
                    </div>  
                </div>    
            </>
        )
    }

    return (
        <>
            <FourOfour />
        </>
    )



}

export default Administrator