import { useState, useEffect} from "react";
import { storage } from '../../firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

const FileUpload = (props) => {

    const [selectedFiles, setSelectedFiles] = useState([]);
   
    const [imgUrl, setImgUrl] = useState([]);
    const [progresspercent, setProgresspercent] = useState(0);
  
    // Upload images to firebase filestore and set url's in state
    useEffect(() => {
        if(selectedFiles) {
            if (!selectedFiles) return;
            [...selectedFiles].map((file) => {
                
                // Loop to upload images, when multiple
                const storageRef = ref(storage, `files/${file.name}`);
                const uploadTask = uploadBytesResumable(storageRef, file);
            
                uploadTask.on("state_changed",
                (snapshot) => {
                    const progress =
                    Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setProgresspercent(progress);
                },
                (error) => {
                    alert(error);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        setImgUrl(imgUrl => [...imgUrl,downloadURL] );
                        //setImgUrl(downloadURL)
                    });
                }
                );    
            })    
        }

    }, [selectedFiles])


    useEffect(() => {
        if(imgUrl) {
            console.log("Her blir img URL satt, så vi kan se formatet ::: ", imgUrl)
            props.setUploadedImages(imgUrl);
        }
    }, [imgUrl])

    return (
        <>
        <div className="file-upload">
            <form>
                <input 
                    type='file'
                    multiple
                    onClick={() => {
                        console.log("Jeg er klikket jeg looover");
                    }}
                    onChange={(e) => setSelectedFiles(e.target.files)}
                />
                <div className="overlay">
                    <div className="holder">
                        <Fab sx={{backgroundColor: "#787F82", color: 'white'}} aria-label="add">
                            <AddIcon />
                        </Fab>
                        <h3>Last opp bilde eller dra & slipp her</h3>
                    </div>
                </div>
            </form>
        </div>
        <div className="upload-img-container">
            {
                !imgUrl &&
                <div className='outerbar'>
                <div className='innerbar' style={{ width: `${progresspercent}%` }}>{progresspercent}%</div>
                </div>
            }
            {
                imgUrl  &&
                imgUrl.map((url) => {
                    return (
                        <img src={url} alt='uploaded file' height={200} />
                    )
                })
            }
        </div>
        </>
    )
}


export default FileUpload