import { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ZoomVideo from '@zoom/videosdk';
import { useOutletContext } from "react-router-dom";
import VideoToggles from '../components/videoChat/videoToggles';
import { ReactComponent as Logo } from '../assets/logo.svg';
import '../styles/pages/meeting.scss';
import PatientInfoPanel from '../components/Doctor/patientInfoPanel';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';

const Meeting = (props) => {
  const navigate = useNavigate();
  const [user, setUser, userDb, setUserDb, meetingInfo, setMeetingInfo] = useOutletContext();
  const [zoomVideo, setZoomVideo] = useState(null);
  const [zoomSession, setZoomSession] = useState(null);
  const [endConsultation, setEndConsultation] = useState(false);

  useEffect(() => {
    if (!meetingInfo?.type || !meetingInfo?.roomName) {
      navigate('/');
    }
  }, [meetingInfo, navigate]);

  useLayoutEffect(() => {
    if (meetingInfo?.type && meetingInfo?.roomName) {
      const initializeZoom = async () => {
        try {
          // Initialize Zoom first
          const client = ZoomVideo.createClient();
          await client.init('en-US', 'Global', { patchJsMedia: true });
          setZoomVideo(client);

          // Get token and join
          const getZoomToken = httpsCallable(functions, 'zoomToken');
          const result = await getZoomToken({ 
            identity: meetingInfo.type || 'provider', 
            roomName: meetingInfo.roomName 
          });

          await client.join(
            result.data.roomName,
            result.data.token,
            meetingInfo.type || 'provider'
          );

          const stream = client.getMediaStream();
          setZoomSession(stream);

          // Request permissions only once
          if (!window.hasRequestedPermissions) {
            try {
              await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: true
              });
              window.hasRequestedPermissions = true;
            } catch (error) {
              console.error('Permission request failed:', error);
              return;
            }
          }

          // Start video and audio without re-requesting permissions
          try {
            await stream.startVideo();
            await stream.startAudio();
            await stream.unmuteAudio();

            // Attach local video
            const localVideo = await stream.attachVideo(client.getCurrentUserInfo().userId, 3);
            if (localVideo) {
              const container = document.querySelector('#videoholder2 video-player');
              container.innerHTML = '';
              container.appendChild(localVideo);
            }
          } catch (error) {
            console.error('Failed to start media:', error);
          }

        } catch (error) {
          console.error('Failed to initialize:', error);
        }
      };

      initializeZoom();
      
      return () => {
        if (zoomVideo) {
          zoomVideo.leave();
          ZoomVideo.destroyClient();
        }
      };
    }
  }, [meetingInfo]);

  // Handle remote participants
  useEffect(() => {
    if (zoomVideo && zoomSession) {
      const attachRemoteVideo = async (userId) => {
        try {
          const remoteVideo = await zoomSession.attachVideo(userId, 3);
          if (remoteVideo) {
            const container = document.querySelector('#videoholder1 video-player');
            container.innerHTML = '';
            container.appendChild(remoteVideo);
          }
        } catch (error) {
          console.error('Failed to attach remote video:', error);
        }
      };

      // Check for existing users immediately
      const checkExistingUsers = async () => {
        const users = zoomVideo.getAllUser();
        const currentUserId = zoomVideo.getCurrentUserInfo().userId;
        
        for (const user of users) {
          if (user.userId !== currentUserId && user.bVideoOn) {
            await attachRemoteVideo(user.userId);
            break; // Only attach the first remote user's video
          }
        }
      };
      checkExistingUsers();

      // Handle new users joining
      zoomVideo.on('user-added', async (payload) => {
        const currentUserId = zoomVideo.getCurrentUserInfo().userId;
        const users = Array.isArray(payload) ? payload : [payload];
        
        for (const user of users) {
          if (user.userId !== currentUserId) {
            await attachRemoteVideo(user.userId);
            break; // Only attach the first remote user's video
          }
        }
      });

      // Handle video state changes
      zoomVideo.on('peer-video-state-change', async (payload) => {
        if (payload.action === 'Start') {
          await attachRemoteVideo(payload.userId);
        }
      });

      return () => {
        zoomVideo.off('peer-video-state-change');
        zoomVideo.off('user-added');
      };
    }
  }, [zoomVideo, zoomSession]);

  // Simple toggle functions
  const toggleVideoOn = async () => {
    if (!zoomSession) return;
    await zoomSession.startVideo();
    const videoElement = await zoomSession.attachVideo(zoomVideo.getCurrentUserInfo().userId, 3);
    const container = document.querySelector('#videoholder2 video-player');
    container.innerHTML = '';
    container.appendChild(videoElement);
  };

  const toggleVideoOff = async () => {
    if (!zoomSession) return;
    await zoomSession.stopVideo();
    document.querySelector('#videoholder2 video-player').innerHTML = '';
  };

  const toggleAudioOn = async () => {
    if (!zoomSession) return;
    await zoomSession.startAudio();
    await zoomSession.unmuteAudio();
  };

  const toggleAudioOff = async () => {
    if (!zoomSession) return;
    await zoomSession.muteAudio();
  };

  const onLeaveButtonClick = () => {
    if (zoomVideo) {
      zoomVideo.leave();
      ZoomVideo.destroyClient();
    }
    if (userDb.type === 'Patient') {
      window.location.href = "/hangup";
    }
  };

  return (
    <>
      <style>
        {"\
        footer { \
          display: none !important; \
        }\
        "}
      </style>
      <style>
        {"\
        header { \
          display: none !important;\
        }\
        "}
      </style>
      <div id="video-chat">
        <div className="meeting-logo">
          <Logo />
        </div>
        <div id="local-media-container">
          {userDb?.type !== "Patient" && <PatientInfoPanel roomName={meetingInfo?.roomName} />}
          <video-player-container id="videoholder1">
            <video-player></video-player>
          </video-player-container>
          <video-player-container id="videoholder2">
            <video-player></video-player>
          </video-player-container>
        </div>
        <div className="meeting-controls">
          <div className="controls-holder">
            <VideoToggles
              user={userDb}
              meetingInfo={meetingInfo}
              videoOn={toggleVideoOn}
              videoOff={toggleVideoOff}
              audioOn={toggleAudioOn}
              audioOff={toggleAudioOff}
              leaveCall={onLeaveButtonClick}
              setEndConsultation={setEndConsultation}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Meeting; 