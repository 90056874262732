import * as React from 'react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VideocamIcon from '@mui/icons-material/Videocam';

export default function Pills() {
  return (
    <>
        <div className="pills">
            <div className="container">
                <div className="inner-pills">

                    <div className="single-pill">
                        <div className="icon">
                            <CalendarMonthIcon />
                        </div>
                        <h4>Bestill time</h4>
                        <p>
                            Som pasient trykker du på bestill time og logger deg på.
                        </p>
                    </div>

                    <div className="single-pill">
                        <div className="icon">
                            <AccessTimeIcon />
                        </div>
                        <h4>Venterom</h4>
                        <p>
                            Etter pålogging blir du satt på vårt virtuelle venteværelse og mottar en SMS når legen er klar.
                        </p>
                    </div>

                    <div className="single-pill">
                        <div className="icon">
                            <VideocamIcon />
                        </div>
                        <h4>Videokonsultasjon</h4>
                        <p>
                            Etter din konsultasjon blir du belastet kr 350,-
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </>
  );
}