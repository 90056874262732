import { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { useRef } from "react";
import jsPDF from "jspdf";
import ReceiptTemplate from "./ReceiptTemplate";
import Button from '@mui/material/Button';

const Income = () => { 

    const [user, setUser, userDb, setUserDb, meetingInfo, setMeetingInfo, setPatientsDb, patientsDb] = useOutletContext();
    const [patients, setPatients] = useState([])
    const [totalSum, setTotalSum] = useState()
    const [totalThisMonth, setTotalThisMonth] = useState()
    const [totalLastMonth, setTotalLastMonth] = useState()

    const d = new Date();
    let month = d.getMonth();

    useEffect(() => {
        patientsDb.forEach((doc) => {
            setPatients(patients => [...patients, doc.data()] );
        });
    }, [])


    useEffect(() => {
        if(patients) {
            const allPayed = patients?.filter(patient => patient.vippsStatus === "Captured")
            const sum = allPayed?.reduce((a, b) => parseInt(a) + parseInt(b.amountPayed), 0)
            setTotalSum(sum)
        }
    }, [patients])

    useEffect(() => {
        if(patients) {
            const allPayed = patients?.filter(patient => patient.vippsStatus === "Captured" && patients[0]?.timePayed?.toDate()?.getMonth() === month)
            const sum = allPayed?.reduce((a, b) => parseInt(a) + parseInt(b.amountPayed), 0)
            setTotalThisMonth(sum)
        }
    }, [patients])


    useEffect(() => {
        if(patients) {
            const allPayed = patients?.filter(patient => patient.vippsStatus === "Captured" && patients[0]?.timePayed?.toDate()?.getMonth() -1)
            const sum = allPayed?.reduce((a, b) => parseInt(a) + parseInt(b.amountPayed), 0)
            setTotalLastMonth(sum)
        }
    }, [patients])
   

    const receiptTemplateRef = useRef(null);

    const handleGeneratePdf = () => {
      const doc = new jsPDF({
        format: "a4",
        unit: "px"
      });
  
      // Adding the fonts
      doc.setFont("Inter-Regular", "normal");
  
      doc.html(receiptTemplateRef.current, {
        async callback(doc) {
          doc.save(`${userDb.hpnr}-faktura-wifiskop.pdf`);
        }
      });
    };

    console.log(userDb.name)
    return (
        <>
            <div className="income">
                <h2>Min konto</h2>
                <div className="income-cards">
                    <div class="card">
                        <h2>kr {totalThisMonth}</h2>
                        <p>Denne måned</p>
                    </div>
                    <div class="card">
                        <h2>kr {totalLastMonth}</h2>
                        <p>Forrige måned</p> 
                    </div>
                    <div class="card">
                        <h2>kr {totalSum}</h2>
                        <p>Totalt</p>
                    </div>
                </div>
            </div>
            <Button style={{marginTop: '25px'}} sx={{mt: 2, color: 'white', borderRadius: '25px', backgroundColor: '#DD0A01', "&:hover": {
                                        backgroundColor: '#DD0A01'
                                    } }}
                                    size="large"
                                    variant="contained" onClick={handleGeneratePdf}>
                Generer PDF
            </Button>   
            <p>Last ned PDF og send til mail@wifiskop.com</p>
          
          <div style={{visibility: 'hidden'}}>
          <div ref={receiptTemplateRef}>
                <ReceiptTemplate hpr={userDb.hpnr} name={userDb.name} accNumber={userDb.accountNumber} totalThisMonth={totalThisMonth} />
            </div>
          </div>
            
      
        </>
    )

}

export default Income