import { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { db } from "../../firebase";
import { collection, query, where, doc, updateDoc, setDoc, addDoc, onSnapshot, serverTimestamp } from "firebase/firestore";
import {useNavigate} from 'react-router-dom';
import Button from '@mui/material/Button';
import VideocamIcon from '@mui/icons-material/Videocam';

const ActivePatients = () => {
    const navigate = useNavigate();
    const [user, setUser, userDb, setUserDb, meetingInfo, setMeetingInfo] = useOutletContext();
    const [activeConsultations, setActiveConsultations] = useState();
    const [joinRoom, setJoinRoom] = useState({})

    useEffect(() => {
        const q = query(collection(db, "Patients"), where("active", "==", true), where("payed", "==", true), where("gettingServed", "==", true), where("treatedById", "==", user?.uid));
        // Quer to use if you want to keep patients in list, even if they are gettingServed.
        //const q = query(collection(db, "Patients"), where("active", "==", true));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const data = querySnapshot.docs.map(doc => doc);
   
            setActiveConsultations(data);
        });
        return () => unsubscribe();
    }, [])

        useEffect(() => {
            if(joinRoom?.type) {
              setMeetingInfo(joinRoom);
              return navigate("/meeting");
            }
        }, [joinRoom])

    if(!activeConsultations) {
        return (
            <>
            </>
        )
    }

    return (
        <>
            {activeConsultations.map((data, index) => {
                console.log("Data fra aktive konsultasjoner ::: ", data.data())
                return (
                    <>
                        <div className="aktiv-behandler-wrapper">
                            <div className="inner">
                                <div key={index}>
                                    <VideocamIcon />
                                    <h2>Du har en aktiv behandling</h2>
                                    <p>Du må avslutte din nåværende behandling før du kan begynne en ny konsultasjon.</p>
                                    <Button variant="contained" onClick={() => {setJoinRoom({type: "provider", roomName: data.data()?.roomName})}}>
                                        Fortsett behandling
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </>
                )
            })}
        </>
    )
}

export default ActivePatients
