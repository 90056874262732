import { useState } from 'react';
import Button from '@mui/material/Button';
import CookieIcon from '@mui/icons-material/Cookie';
import { useEffect } from 'react';

export default function Cookies() {

    function setCookie(name,value,days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    }


    const [cookieState, setCookieState] = useState(false);


    const setCookieTrue = () => {
        setCookie("cookie", true)
        setCookieState(true);
    }

    return (
        <>
        {cookieState === false  && (
            <>
                <div className="aktiv-behandler-wrapper">
                    <div className="inner">
                        <div>
                            <CookieIcon />
                            <h2>Vi bruker cookies</h2>
                            <p>Cookies brukes til å forbedre din opplevelse.</p>
                            <Button variant="contained" onClick={() => setCookieTrue()}>
                                Aksepter alle
                            </Button>
                        </div>
                    </div>
                </div>
            </>
        )}
        </>
    );
}