import { useOutletContext } from "react-router-dom";
import {ReactComponent as Wait} from '../../assets/wait-approval.svg';

const WaitingForApproval = () => {

    const [user, setUser, userDb, setUserDb] = useOutletContext();

    if(user && userDb?.type === "Doctor" && userDb?.approvedDoctor === false) {
        return (
            <>
                <div className="accept-doctor">
                    <div className="container">
                        <Wait />
                        <h1>Vi jobber nå med å godkjenne deg som lege.</h1>
                        <p>Så snart godkjenningen er ferdig vil du kunne starte å benytte appen</p>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
        </>
    )

}

export default WaitingForApproval