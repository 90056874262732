import { useState } from "react";

const ReceiptTemplate = (props) => {
const dato = new Date();
const month = ["Januar","Februar","Mars","April","Mai","Juni","Juli","August","September","October","November","Desember"];
let d = dato.getDate();
let m = month[dato.getMonth()];
let aa = dato.getFullYear();
  return (
    <div style={{fontSize: '10px'}}>
        <p>{d}.{m}.{aa}</p>
        <br />
      <h2 style={{marginBottom: '20px'}}>Faktura til WifiSkop</h2>
      <p>{props.name} har opparbeidet seg totalt kr: {props.totalThisMonth} denne måned.</p>
      <br />
      <p>{props.name} ønsker å få dette betalt til kontonr: {props.accNumber} </p>
      <p>Denne fakturaen er opprettet hos WifiSkop </p>
      <p> av {props.name} med hpr nr: {props.hpr}</p>
    </div>
  ); 
};

export default ReceiptTemplate;
