import {useState, useEffect} from "react";
import { useOutletContext } from "react-router-dom";
import { db } from "../../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const SetDoctorInformation = () => {

    const [user, setUser, userDb, setUserDb] = useOutletContext();

    const [mobile, setMobile] = useState();
    const [email, setEmail] = useState();
    const [accountNumber, setAccountNumber] = useState();
    const [hpnr, setHpnr] = useState();

    const setDoctorInformation = async (event) => {

        const userRef = doc(db, "Users", user?.uid);
        updateDoc(userRef, { 
        name: user?.displayName,
        id: user?.uid,
        email: email,
        mobile: mobile,
        accountNumber: accountNumber,
        hpnr: hpnr,
        approvedDoctor: false,
        firstTimeLogin: false
        }).then(async (result) => {
            const docRef = doc(db, "Users", user?.uid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                console.log("Prøver å oppdattere dokument");
                setUserDb(docSnap.data())
            } else {
                console.log("Klarte ikke sette DB object etter creation")
            }
        }).catch((err) => {
            console.log("Error på DB update ::: ", err)
        });
        event.preventDefault();
    }

    if(user && userDb?.firstTimeLogin === true && userDb?.type === "Doctor") {
        return (
            <>
                    <div className="index-doctor">
                        <div className="container">
                            <h1>Vi må vite litt mer om deg, før du kan starte å jobbe som lege i Wifiskopet</h1>
                            <form style={{ maxWidth: 700, margin: '0px auto'}}>
                                <Box
                                    component="form"
                                    sx={{
                                        '& > :not(style)': { m: 1, width: '25ch' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField 
                                        id="outlined-basic" 
                                        label="Telefonnummer" 
                                        variant="outlined"
                                        type="tel"
                                        onChange={(e) => {
                                            setMobile(e.target.value)
                                        }}
                                    />
                                    <TextField 
                                        id="outlined-basic" 
                                        label="E-post" 
                                        variant="outlined" 
                                        onChange={(e) => {
                                            setEmail(e.target.value)
                                        }}
                                    />
                                    <TextField 
                                        id="outlined-basic" 
                                        label="Kontonummer" 
                                        variant="outlined"
                                        onChange={(e) => {
                                            setAccountNumber(e.target.value)
                                        }} 
                                    />
                                    <TextField 
                                        id="outlined-basic" 
                                        label="HPNR Nummer" 
                                        variant="outlined"
                                        onChange={(e) => {
                                            setHpnr(e.target.value)
                                        }}
                                    />
                                </Box>
                                <div className="btn-holder">
                                    <Button
                                        sx={{mt: 5, color: 'white', borderRadius: '25px', backgroundColor: '#DD0A01', "&:hover": {
                                            backgroundColor: '#DD0A01'
                                        } }}
                                        size="large"
                                        variant="contained"
                                        onClick={(event) => {
                                            setDoctorInformation(event);
                                        }}
                                    >
                                    GÅ VIDERE
                                    <ArrowForwardIcon sx={{ml: 1}} />
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
            </>
        )
    }

    return (
        <>
        </>
    )
}

export default SetDoctorInformation