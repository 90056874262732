import { useOutletContext } from "react-router-dom";
import VideoChat from "../videoChat/provider";

const MainPatientPage = () => {
    
    const [user, setUser, userDb] = useOutletContext();

    if(user && userDb?.firstTimeLogin === false && userDb?.type === "Patient") {
        return ( 
            <>
                  <VideoChat type="patient" user={userDb} />
            </>
        )
    }

    return (
        <>
        </>
    )
  
}

export default MainPatientPage