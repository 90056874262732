import * as React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Faq() {

  return (
    <>
        <div className="faq">
            <div className="container">
                <div className="inner-faq">
                    <h2>FAQ</h2>
                    <p>
                        Under finner du svar på ofte stilte spørsmål
                    </p>
                    <div className="accordion-wrapper">
                    <h3>Generelle spørsmål</h3>

                    
                        <Accordion sx={{boxShadow: 'none', background: '#FAEFE6', borderRadius: '12px !important', marginBottom: '15px'}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={{minHeight: '64px', padding: '0 25px', border: 'none', borderRadius: '12px', '&:before': 'display: none !important'}}
                            >
                            <h4>Hvor mye koster en legetime?</h4>
                            </AccordionSummary>
                            <AccordionDetails>
                            <div className="inner-single-faq">
                                <p>
                                    Videokonsultasjon kr 350,-
                                </p>
                            </div>
                            </AccordionDetails>
                        </Accordion>
                        
                    
                   

                    <h3>Betaling</h3>
                    <Accordion sx={{boxShadow: 'none', background: '#FAEFE6', borderRadius: '12px !important', border: 'none !important'}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={{minHeight: '64px', padding: '0 25px', border: 'none', borderRadius: '12px'}}
                            >
                            <h4>Hva slags betalingsløsning tilbyr dere?</h4>
                            </AccordionSummary>
                            <AccordionDetails>
                            <div className="inner-single-faq">
                                <p>
                                    Vipps betaling sikrer deg som bruker at betalingen skjer etter at konsultasjonen har skjedd
                                </p>
                            </div>
                            </AccordionDetails>
                        </Accordion>
                    

                    <h3>Andre spørsmål</h3>

                    <Accordion sx={{boxShadow: 'none', background: '#FAEFE6', borderRadius: '12px !important', marginBottom: '15px'}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={{minHeight: '64px', padding: '0 25px', border: 'none', borderRadius: '12px', '&:before': 'display: none !important'}}
                            >
                            <h4>Hvor lenge varer en konsultasjon?</h4>
                            </AccordionSummary>
                            
                            <AccordionDetails>
                            <div className="inner-single-faq">
                                <p>
                                    En videokonsultasjon varer normalt i fra 5 til 15 minutter. Dersom det viser seg at du vil
                                    trenge mere tid enn dette, vil du ofte ha behov for å møte til en fysiskt konsultasjon hos din lege.
                                </p>
                            </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>  
                </div>
            </div>
        </div>
    </>
  );
}