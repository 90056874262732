import * as React from 'react';
import Hero from '../components/Elements/hero';
import Pills from '../components/Elements/pills';
import CTA from '../components/Elements/cta';
import Cards from '../components/Elements/cards';
import HelpCase from '../components/Elements/helpcase';
import Faq from '../components/Elements/faq';
import Snackybar from '../components/Elements/snackybar';
import CTANoImage from '../components/Elements/cta-noimage';
import Cookies from '../components/Elements/cookie-popup';

export default function Index() {

  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

  const hasCookies = getCookie("cookie");

  return (
    <>
        <Hero type="Patient" />
        <Pills />
        <CTANoImage />
        <CTA />
        {/*
        <Cards />   
        <HelpCase />
        */}
        
        <Faq />
        <Snackybar />
        { !hasCookies && 
          <Cookies />
        }
        
    </>
  );
}