import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { db } from "../../firebase";
import { doc, getDoc, updateDoc, serverTimestamp, query, where, collection, getDocs, onSnapshot } from "firebase/firestore";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Lightbox from './lightbox';
import FsLightbox from 'fslightbox-react';

const PatientInfoPanel = (props) => {

        const [patientInfo, setPatientInfo] = useState();
        const [toggler, setToggler] = useState(false);

        useEffect(() => {

                const q = query(collection(db, "Patients"), where("roomName", "==", props.roomName));
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const data = querySnapshot.docs.map(doc => doc.data());
                    setPatientInfo(data);
                });
                return () => unsubscribe();
        }, [])

        useEffect(() => {
            console.log("Patient info form panel ::: ", patientInfo)
            console.log("adaslkdljasdk ladklj asdkljaskldjaskljdakslj :::  " , patientInfo?.[0]?.pains)
        }, [patientInfo])

    
        if(patientInfo) {

            return (
                <>
                
                    <div className="patient-info">
                        <div className="upper-info">
                            <h3>{patientInfo?.[0]?.name}</h3>
                            <p>{patientInfo?.[0]?.ssn}</p>
                        </div>
    
                        <div className="prefilled-info">
                            <p>Informasjon:</p>
                            <Box
                                component="form"
                                sx={{'& > :not(style)': { width: '100%', mb: 4 },}}
                                noValidate
                                autoComplete="off"
                            >
                                <FormControl>
                                    <InputLabel id="demo-simple-select-label">Opplever du smerter?</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Opplever du smerter?"
                                        defaultValue={patientInfo?.[0]?.pains}
                                        value={patientInfo?.[0]?.pains} 
                                        disabled
                                    >
                                        <MenuItem value="Ja">Ja</MenuItem>
                                        <MenuItem value="Nei">Nei</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
    
                            <Box
                                component="form"
                                sx={{'& > :not(style)': { width: '100%', mb: 4 },}}
                                noValidate
                                autoComplete="off"
                            >
                                <FormControl>
                                    <InputLabel id="demo-simple-select-label">Trenger du sykemelding eller legeerklering?</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Trenger du sykemelding eller legeerklering?"
                                        defaultValue={patientInfo?.[0]?.sickLeave}
                                        value={patientInfo?.[0]?.sickLeave}
                                        disabled
                                    >
                                        <MenuItem value="Ja">Ja</MenuItem>
                                        <MenuItem value="Nei">Nei</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box
                                component="form"
                                sx={{'& > :not(style)': { width: '100%', mb: 4 },}}
                                noValidate
                                autoComplete="off"
                            >
                                <FormControl>
                                    <InputLabel id="demo-simple-select-label">Har du en infeksjon?</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Har du en infeksjon?"
                                        defaultValue={patientInfo?.[0]?.infection}
                                        value={patientInfo?.[0]?.infection}
                                        disabled
                                    >
                                        <MenuItem value="Ja">Ja</MenuItem>
                                        <MenuItem value="Nei">Nei</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box
                                component="form"
                                sx={{'& > :not(style)': { width: '100%', mb: 4 },}}
                                noValidate
                                autoComplete="off"
                            >
                                <FormControl>
                                    <InputLabel id="demo-simple-select-label">Trenger du en resept eller resept fornyelse?</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Trenger du en resept eller resept fornyelse?"
                                        defaultValue={patientInfo?.[0]?.prescription}
                                        value={patientInfo?.[0]?.prescription}
                                        disabled
                                    >
                                        <MenuItem value="Ja">Ja</MenuItem>
                                        <MenuItem value="Nei">Nei</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box
                                component="form"
                                sx={{'& > :not(style)': { width: '100%', mb: 4, mt: 4 },}}
                                noValidate
                                autoComplete="off"
                            >
                            <TextField
                                        multiline
                                        label="Kommentar"
                                        rows={4}
                                        maxRows={8}
                                        disabled
                                    />
                            </Box>
    
                            <Box
                                component="form"
                                sx={{'& > :not(style)': { width: '100%', mb: 4 },}}
                                noValidate
                                autoComplete="off"
                            >
                                <Button
                                        sx={{mt: 4, color: 'white', borderRadius: '25px', backgroundColor: '#DD0A01', "&:hover": {
                                            backgroundColor: '#DD0A01'
                                        } }}
                                        size="large"
                                        variant="contained"
                                    >
                                        ÅPNE JOURNAL
                                        <ArrowForwardIcon sx={{ml: 1}} />
                                    </Button>
                            </Box>
                            
                        </div>
                    </div>
                </>
            )


        }

    

    return (
        <>
        </>
    )


}

export default PatientInfoPanel
