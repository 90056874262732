import Button from '@mui/material/Button';
import {ReactComponent as Image} from '../assets/hangup.svg';
import LoginIcon from '@mui/icons-material/Login';

const Hangup = () => {

    return (
        <>
            <div className="hangup">
                <div className="container">
                    <div className="svg-holder">
                        <Image />
                    </div>
                    <h2>Du har nå forlatt samtalen</h2>
                    <div className="btn-wrapper">
                        <a href="/">
                            <Button size="large" variant="contained">
                                <LoginIcon />
                                TILBAKE TIL DASHBORD
                            </Button>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Hangup;