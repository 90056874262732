import {useState, useEffect, useRef} from "react";
import { db } from "../../firebase";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { useOutletContext } from "react-router-dom";
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Curve from '../../assets/curve.png';
import {ReactComponent as Indeximage} from '../../assets/index-image.svg';
import FileUpload from "../utils/fileUpload";
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import ActiveCOnsultations from "./activeConsultations";
import PreviousTreatments from "./previousTreatments";
import TextField from '@mui/material/TextField';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebase';

const Consultation = (props) => {

    const [infoGiven, setInfoGiven] = useState({});
    const [uploadedImages, setUploadedImages] = useState([])
    const [haveInfo, setHaveInfo] = useState();
    const [waitingForAppointment, setWaitingForAppointment] = useState(false);

    const [open, setOpen] = useState(false);
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('md');
    const [showPayment, setShowPayment] = useState(false);

    const [user, setUser, userDb, setUserDb, meetingInfo, setMeetingInfo] = useOutletContext();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleMaxWidthChange = (event) => {
        setMaxWidth(
        // @ts-expect-error autofill of arbitrary value is not handled.
        event.target.value,
        );
    };

    const handleFullWidthChange = (event) => {
        setFullWidth(event.target.checked);
  };

    useEffect(() => {
        if(infoGiven?.pains?.length > 0 && infoGiven?.prescription?.length > 0 && infoGiven?.infection?.length > 0 ) {
            return setHaveInfo(false)
        }
        setHaveInfo(true)
    }, [infoGiven])

    const payWithVipps = async () => {
        try {
            if (!userDb?.mobile) {
                alert('Mobilnummer mangler');
                return;
            }

            console.log('Initializing payment with mobile:', userDb.mobile);
            
            const vippsInit = httpsCallable(functions, 'vippsPaymentInitCall');
            const result = await vippsInit({
                mobileNumber: userDb.mobile
            });
            
            console.log('Payment response:', result);

            if (!result.data?.url) {
                throw new Error('Mangler betalings-URL');
            }

            // Create appointment first
            const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let roomName = '';
            const roomNameGenerator = (length) => {
                let result = '';
                const charactersLength = characters.length;
                for (let i = 0; i < length; i++) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                } 
                roomName = result;
            }
            roomNameGenerator(16);

            // Create the appointment document
            await setDoc(doc(db, "Patients", roomName), {
                mobile: userDb?.mobile,
                ssn: userDb?.ssn,
                name: props.user.name ?? "",
                gender: props.user.gender ?? "",
                pains: infoGiven?.pains,
                prescription: infoGiven?.prescription,
                sickLeave: infoGiven?.sickLeave,
                infection: infoGiven?.infection,
                images: uploadedImages,
                active: true,
                gettingServed: false,
                roomName: roomName ?? "",
                patientId: props.user.id ?? "",
                appointmentDone: false,
                payed: false,
                paymentIdVipps: result.data.orderId ?? "",
                amountPayed: "350",
                timePayed: serverTimestamp(),
            });

            console.log("Appointment created with orderId:", result.data.orderId);
            setWaitingForAppointment(true);

            // Then redirect to Vipps
            window.location.href = result.data.url;

        } catch (error) {
            console.error('Payment initialization failed:', error);
            alert(`Betaling feilet: ${error.message}`);
        }
    };

    return (
        <>
            <div id="waiting-room" class="hidden">
                <p>Du er nå i venterommet for å få snakke med en lege</p>
                <p>Mens du venter kan du fylle ut skjema angående din helse</p>
            </div>
            {!waitingForAppointment && 
                <>
                    
                    <div className="intro">
                        <div className="container">
                            <h1>Velkommen {props.user?.name} 👋</h1>
                            {/*<h1>Heisann {props.user?.name}! 👋</h1>*/}
                            <img className="curve" src={Curve} />
                            <div className="btn-holder">
                                <Button
                                    sx={{mt: 4, color: 'white', borderRadius: '25px', backgroundColor: '#DD0A01', "&:hover": {
                                        backgroundColor: '#DD0A01'
                                    } }}
                                    size="large"
                                    variant="contained"
                                    onClick={handleClickOpen}
                                >
                                    BESTILL TIME
                                    <ArrowForwardIcon sx={{ml: 1}} />
                                </Button>
                            </div>
                        </div>
                    </div>

                <div className="container">
                    <div className="index-image">
                        <Indeximage />
                    </div>
                </div>

                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={open}
                    onClose={handleClose}
                    className="heisannboks"
                >

                    <div className="dialog-holder">
                    {!showPayment && 
                        <>
                        <h2>Kommentar</h2>
                        <p>
                            Det er kun legen som får se det du skriver, og vil kun bli brukt
                            for å kunne gi deg best mulig hjelp
                        </p>
                        <div className="border-r"></div>
                        
                            <form className="inner-dialog-holder">
                                <div className="upper">
                                <Box
                                component="form"
                                sx={{'& > :not(style)': { m: 1, width: '25ch', mb: 4 },}}
                                noValidate
                                autoComplete="off"
                            >
                                <FormControl>
                                    <InputLabel id="demo-simple-select-label">Opplever du smerter?</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Opplever du smerter?"
                                        onChange={(event) => {
                                            setInfoGiven({...infoGiven, pains: event.target.value});
                                        }}
                                    >
                                        <MenuItem value="Ja">Ja</MenuItem>
                                        <MenuItem value="Nei">Nei</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>

                            <Box
                                component="form"
                                sx={{'& > :not(style)': { m: 1, width: '25ch',  mb: 4 },}}
                                noValidate
                                autoComplete="off"
                            >
                                <FormControl>
                                    <InputLabel id="demo-simple-select-label">Trenger du sykemelding eller legeerklering?</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Trenger du sykemelding eller legeerklering?"
                                        onChange={(event) => {
                                            setInfoGiven({...infoGiven, sickLeave: event.target.value});
                                        }}
                                    >
                                        <MenuItem value="Ja">Ja</MenuItem>
                                        <MenuItem value="Nei">Nei</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box
                                component="form"
                                sx={{'& > :not(style)': { m: 1, width: '25ch', mb: 4 },}}
                                noValidate
                                autoComplete="off"
                            >
                                <FormControl>
                                    <InputLabel id="demo-simple-select-label">Har du en infeksjon?</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Har du en infeksjon"
                                        onChange={(event) => {
                                            setInfoGiven({...infoGiven, infection: event.target.value});
                                        }}
                                    >
                                        <MenuItem value="Ja">Ja</MenuItem>
                                        <MenuItem value="Nei">Nei</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>

                            <Box
                                component="form"
                                sx={{'& > :not(style)': {m: 1, width: '25ch',  mb: 4 },}}
                                noValidate
                                autoComplete="off"
                            >
                                <FormControl>
                                    <InputLabel id="demo-simple-select-label">Trenger du en resept eller resept fornyelse?</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Trenger du en resept eller resept fornyelse?"
                                        onChange={(event) => {
                                            setInfoGiven({...infoGiven, prescription: event.target.value});
                                        }}
                                    >
                                        <MenuItem value="Ja">Ja</MenuItem>
                                        <MenuItem value="Nei">Nei</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                                </div>
                            <div className="under">
                            <Box
                                component="form"
                                sx={{'& > :not(style)': { width: '25ch',  mb: 5 },}}
                                noValidate
                                autoComplete="off"
                            >   
                                <FileUpload setUploadedImages={setUploadedImages} />

                            </Box>
                            <Box
                                component="form"
                                sx={{'& > :not(style)': { width: '100%',  mb: 2 },}}
                                noValidate
                                autoComplete="off"
                            >
                                <TextField
                                    multiline
                                    label="Kommentar"
                                    rows={4}
                                    maxRows={8}
                                />
                            </Box>
                            
                            <div className="btn-holder">
                                <Button
                                    id="join-button" disabled={haveInfo} onClick={async (event) => {
                                        {/* Initiate payment on click */}
                                        setShowPayment(true);
                                    }}
                                    sx={{mt: 5, color: 'white', borderRadius: '25px', backgroundColor: '#DD0A01', "&:hover": {
                                        backgroundColor: '#DD0A01'
                                    } }}
                                    size="large"
                                    variant="contained"
                                >
                                    TIL BETALING
                                    <ArrowForwardIcon sx={{ml: 1}} />
                                </Button>
                            </div>
                            </div>
                            
                     
                            </form>
                            </>
                        }

                        {showPayment && 
                        <>
                            <h2>Fullfør betaling</h2>
                            <p>
                                Etter betaling er fullført, vil du motta en SMS når legen er klar til å behandle deg.
                            </p>
                            <div className="border-r"></div>
                            <div className="btn-holder" style={{display: 'flex', justifyContent: 'center'}}>
                                <Button 
                                    size="large"
                                    sx={{ color: 'white', borderRadius: '25px', backgroundColor: '#DD0A01', "&:hover": {
                                        backgroundColor: '#DD0A01'
                                    } }}
                                    variant="contained"
                                    onClick={(e) => {
                                    payWithVipps();
                                }}>
                                    BETAL MED VIPPS kr 350,-
                                </Button>
                            </div>
                            </>
                            }
                        {/* Payment solution */}
                    </div>
        
                </Dialog>
                <ActiveCOnsultations setJoinRoom={props.setJoinRoom}/>
                { /* <PreviousTreatments /> 
                        <div className="btn-holder">
                            <Button 
                                sx={{mt: 5, color: 'white', borderRadius: '25px', backgroundColor: '#DD0A01', "&:hover": {
                                    backgroundColor: '#DD0A01'
                                } }}
                                size="large"
                                variant="contained" 
                                onClick={handleClickOpen}>
                                BESTILL NY TIME hei
                                <ArrowForwardIcon sx={{ml: 1}} />
                            </Button>
                        </div>
                */ }
                </>
            }
            
        </>
    )
}

export default Consultation;