import * as React from "react";
import {useState, useEffect} from "react";
import { Outlet } from 'react-router-dom';
import { db, auth, loginObserver } from "./firebase";
import { doc, setDoc, getDoc, query, where, collection, getDocs } from "firebase/firestore";
import Layout from "./components/Layout";
import "./styles/main.scss";
import { ThemeProvider, createTheme } from '@mui/material/styles';

export default function App() {

      const [user, setUser] = useState();
      const [userDb, setUserDb] = useState();
      const [meetingInfo, setMeetingInfo] = useState({}); 
      const [patientsDb, setPatientsDb] = useState({})
      const [patientsAll, setPatientsAll] = useState({})

      useEffect(() => {
        console.log("Endringer i meeting info ::: ", meetingInfo);
        console.log("Edrnigner i set meeting info ::: ", setMeetingInfo);
      }, [meetingInfo, setMeetingInfo]);

    //#region Auth and get user data
     const createAndGetUserDb = async () => {
        console.log("Prøver å kjøre noe shiet");
        const docRef = doc(db, "Users", user?.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setUserDb(docSnap.data())
        } else {
                const registrationType = window.localStorage.getItem('registrationType');
                // Create database
                const userRef = doc(db, "Users", user?.uid);
                setDoc(userRef, {
                type: registrationType,
                firstTimeLogin: true
                }).then(async (result) => {
                    const docRef = doc(db, "Users", user?.uid);
                    const docSnap = await getDoc(docRef);
                    if (docSnap.exists()) {
                        setUserDb(docSnap.data())
                    } else {
                        console.log("Klarte ikke sette DB object etter creation")
                    }
                }).catch((err) => {
                console.log("Error på DB update ::: ", err)
                });
            
        }
     }
    
    const getPatientHistory = async () => {

        const q = query(collection(db, "Patients"), where("treatedById", "==", user?.uid));
        const querySnapshot = await getDocs(q);
        setPatientsDb(querySnapshot)
        /*querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          setP
          console.log(doc.id, "asdcasddsa => ", doc.data());
        });*/
 
    }

    const getAllPatients = async () => { 
        const q = query(collection(db, "Patients"));
        const querySnapshot = await getDocs(q);
        setPatientsAll(querySnapshot)
    }



     

    // Check if user is loged in, or out
    loginObserver(auth, (userData) => {
        setUser(userData)
    });

     useEffect(() => {
        console.log("User er endret til ::: ", user);
        if(user) {
            createAndGetUserDb();
            getPatientHistory();
            getAllPatients();
        }
     }, [user])

    //#endregion

    const theme = createTheme({
        palette: {
          primary: {
            main: '#DD0A01',
          }
        },
      });
      
    return (
        <>
            <ThemeProvider theme={theme}>
                <Layout>
                    <Outlet context={[user, setUser, userDb, setUserDb, meetingInfo, setMeetingInfo, setPatientsDb, patientsDb, patientsAll]} />
                </Layout>
            </ThemeProvider>
        </>
    ) 

}