import {useState, useEffect} from "react";
import { useOutletContext } from "react-router-dom";
import WaitingRoom from "../Doctor/waitingRoom";
import Income from "../Doctor/income";
import Consultation from "../Patient/consultation";
import {useNavigate} from 'react-router-dom';
import VideocamIcon from '@mui/icons-material/Videocam';
import PersonIcon from '@mui/icons-material/Person';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { Button } from "@mui/material";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

const VideoChat = (props) => {
  
      const navigate = useNavigate();
      const [joinRoom, setJoinRoom] = useState({});

      const [menuToggle, setMenuToggle] = useState({
          consultation: true,
          support: false,
          myPage: false,
          open: false,
      });

      const [user, setUser, userDb, setUserDb, meetingInfo, setMeetingInfo] = useOutletContext();

      console.log("User DB ::: ", userDb)
      console.log("Dette er user ::: ", user)
      
      useEffect(() => {
          if(joinRoom?.type) {
            setMeetingInfo(joinRoom);
            return navigate("/meeting");
          }
      }, [joinRoom])

      if(props.type === "patient") {
            return (
              <>
                <Consultation 
                  setJoinRoom={setJoinRoom}
                  user={props.user}
                />
              </>
            )
      }

      return (
        <>
            <style>
                {"\
                footer { \
                    display: none !important; \
                }\
                "}
            </style>
            <style>
                {"\
                header .container { \
                    max-width: unset !important;\
                    padding: 0 20px !important;\
                }\
                "}
            </style>
            <style>
                {"\
                @media screen and (max-width: 992px) {\
                    header .container { \
                        padding: 0 0px !important;\
                    }\
                }\
                "}
            </style>
            <style>
                {"\
                header { \
                    box-shadow: none !important;\
                }\
                "}
            </style>
            <div className="hovedwrapper">
    
                <div className={menuToggle.open ? 'sidebar mob-open' : 'sidebar'}>
                    <div className="menu">
                        <ul>
                            <li>
                                <a  
                                    className={menuToggle.consultation ? 'active' : ''}
                                    onClick={() => setMenuToggle({...menuToggle, consultation: true, support: false, myPage: false, open: false})}
                                >
                                    <VideocamIcon />
                                    <span>Ventende pasienter</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    className={menuToggle.myPage ? 'active' : ''}
                                    onClick={() => setMenuToggle({...menuToggle, consultation: false, support: false, myPage: true, open: false})}
                                >
                                    <PersonIcon />
                                    <span>Min konto</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    className={menuToggle.support ? 'active' : ''}
                                    onClick={() => setMenuToggle({...menuToggle, consultation: false, support: true, myPage: false, open: false})}
                                >
                                    <SupportAgentIcon />
                                    <span>Support</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="content">
                    <div className="inner-content">
                        <div onClick={() => setMenuToggle({...menuToggle, open: false})} className={menuToggle.open ? 'overlay open' : 'overlay'}></div>
                        <Button
                        className="menu-btn"
                        onClick={() => setMenuToggle({...menuToggle, open: true})}
                        sx={{backgroundColor: '#d2d2d2', marginBottom: '15px'}}
                            variant="cotnained"
                        >
                            <MenuOpenIcon sx={{marginRight: '5px'}} />
                            MENY
                        </Button>
                        {menuToggle.consultation && <WaitingRoom setJoinRoom={setJoinRoom} />}
                        {menuToggle.myPage && <Income />}
                        {menuToggle.support && (
                            <>
                                <div className="support-tab">
                                    <h2>Support</h2>
                                    <p>Lurer du på noe? <a href="mailto:mail@mail.com">Klikk her for å ta kontakt med oss på e-post</a></p>
                                </div>
                            </>
                        )}
                    </div>
                </div>  
            </div>    
        </>
    )
}
export default VideoChat;