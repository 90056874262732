import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

// Pages
import App from "./App.js";
import IndexPage from './pages/index.jsx';
import IndexPageSignup from './pages/index-signup';
import DoctorPage from "./pages/doctor.jsx";
import GDPR from "./pages/gdpr.jsx";
import PatientPage from "./pages/patient.jsx";
import Meeting from "./pages/meeting.jsx";
import Administrator from "./pages/administrator";
import Hangup from "./pages/hangup"
import PageNotFound from "./pages/404"

ReactDOM.render(
  <BrowserRouter>
    <Routes>
        <Route path="/" element={<App />}>
        <Route path="/" element={<IndexPage />} />
        
        {/* <Route path="/" element={<IndexPageSignup />} /> */}

         <Route path="/personvern" element={<GDPR />} />
        
      
          <Route path="/doctor" element={<DoctorPage />} />
          <Route path="/patient" element={<PatientPage />} />
          <Route path="/meeting" element={<Meeting />} />
          <Route path="/administrator" element={<Administrator />} />
          <Route path="/hangup" element={<Hangup />} />

      
      
        {/* 👇️ only match this when no other routes match */}
        <Route path="*" element={<PageNotFound />} />

      </Route>
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);