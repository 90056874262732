import {useState, useEffect} from "react";
import { database, db, auth, loginObserver } from "../../firebase";
import { collection, query, where, onSnapshot, doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import { FormatColorFillSharp } from "@mui/icons-material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TablePagination from '@mui/material/TablePagination';

const ApproveDoctors = (props) => {

    const [doctorList, setDoctorList] = useState();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };


    const setApproved = (data) => {
        const userRef = doc(db, "Users", data.id);
        updateDoc(userRef, {
            approvedDoctor: true,
        }).then(async (result) => {
            const docRef = doc(db, "Users", data.id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                console.log("Prøver å oppdattere dokument");
            } else {
                console.log("Klarte ikke sette DB object etter creation")
            }
        }).catch((err) => {
            console.log("Error på DB update ::: ", err)
        });
    }

    useEffect(() => {
        const q = query(collection(db, "Users"), where("approvedDoctor", "==", false), where("type", "==", "Doctor"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const data = querySnapshot.docs.map(doc => doc.data());
            setDoctorList(data);
        });
        return () => unsubscribe();
    }, [])


    useEffect(() => {
        console.log("Til godkjenning ::: ", doctorList);
    }, [doctorList])

    if(!props.user.superUser) {
        return (
            <>
            </>
        )
    }

    if(!doctorList) {
        return (
            <>
                <p>Laster</p>
            </>
        )
    }

    return (
        <>
            <div className="table-behandlinger">
                <h2>Leger til godkjenning</h2>
                <Paper sx={{ width: '100%' }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 350 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Navn</TableCell>
                                    <TableCell>Godkjenn behandler</TableCell>
                                    <TableCell>HPR</TableCell>
                                    <TableCell>E-post</TableCell>
                                    <TableCell>Telefonnummer</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {doctorList.map((data, index) => {
                                    return (
                                        <>
                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell>{data.name}</TableCell>
                                                <TableCell>
                                                <Button
                                                    sx={{ color: 'white', borderRadius: '24px', fontSize: '11px', backgroundColor: '#DD0A01', "&:hover": {
                                                        backgroundColor: '#DD0A01'
                                                    } }}
                                                    size="small"
                                                    onClick={() => {
                                                        setApproved(data);
                                                    }}
                                                    variant="contained"
                                                >
                                                    GODKJENN
                                                </Button>
                                                </TableCell>
                                                <TableCell>{data.hpnr}</TableCell>
                                                <TableCell>{data.email}</TableCell>
                                                <TableCell>{data.mobile}</TableCell>
                                            </TableRow>
                                        </>
                                    )
                                })}
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell>qweqweqw</TableCell>
                                    <TableCell>
                                    <Button
                                        sx={{ color: 'white', borderRadius: '24px', fontSize: '11px', backgroundColor: '#DD0A01', "&:hover": {
                                            backgroundColor: '#DD0A01'
                                        } }}
                                        size="small"

                                        variant="contained"
                                    >
                                        GODKJENN
                                    </Button>
                                    </TableCell>
                                    <TableCell>qwe</TableCell>
                                    <TableCell>qwe</TableCell>
                                    <TableCell>qweqweqwe</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell>qweqweqw</TableCell>
                                    <TableCell>
                                    <Button
                                        sx={{ color: 'white', borderRadius: '24px', fontSize: '11px', backgroundColor: '#DD0A01', "&:hover": {
                                            backgroundColor: '#DD0A01'
                                        } }}
                                        size="small"

                                        variant="contained"
                                    >
                                        GODKJENN
                                    </Button>
                                    </TableCell>
                                    <TableCell>qwe</TableCell>
                                    <TableCell>qwe</TableCell>
                                    <TableCell>qweqweqwe</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell>qweqweqw</TableCell>
                                    <TableCell>
                                    <Button
                                        sx={{ color: 'white', borderRadius: '24px', fontSize: '11px', backgroundColor: '#DD0A01', "&:hover": {
                                            backgroundColor: '#DD0A01'
                                        } }}
                                        size="small"

                                        variant="contained"
                                    >
                                        GODKJENN
                                    </Button>
                                    </TableCell>
                                    <TableCell>qwe</TableCell>
                                    <TableCell>qwe</TableCell>
                                    <TableCell>qweqweqwe</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell>qweqweqw</TableCell>
                                    <TableCell>
                                    <Button
                                        sx={{ color: 'white', borderRadius: '24px', fontSize: '11px', backgroundColor: '#DD0A01', "&:hover": {
                                            backgroundColor: '#DD0A01'
                                        } }}
                                        size="small"

                                        variant="contained"
                                    >
                                        GODKJENN
                                    </Button>
                                    </TableCell>
                                    <TableCell>qwe</TableCell>
                                    <TableCell>qwe</TableCell>
                                    <TableCell>qweqweqwe</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell>qweqweqw</TableCell>
                                    <TableCell>
                                    <Button
                                        sx={{ color: 'white', borderRadius: '24px', fontSize: '11px', backgroundColor: '#DD0A01', "&:hover": {
                                            backgroundColor: '#DD0A01'
                                        } }}
                                        size="small"

                                        variant="contained"
                                    >
                                        GODKJENN
                                    </Button>
                                    </TableCell>
                                    <TableCell>qwe</TableCell>
                                    <TableCell>qwe</TableCell>
                                    <TableCell>qweqweqwe</TableCell>
                                </TableRow>
                                
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell>qweqweqw</TableCell>
                                    <TableCell>
                                    <Button
                                        sx={{ color: 'white', borderRadius: '24px', fontSize: '11px', backgroundColor: '#DD0A01', "&:hover": {
                                            backgroundColor: '#DD0A01'
                                        } }}
                                        size="small"

                                        variant="contained"
                                    >
                                        GODKJENN
                                    </Button>
                                    </TableCell>
                                    <TableCell>qwe</TableCell>
                                    <TableCell>qwe</TableCell>
                                    <TableCell>qweqweqwe</TableCell>
                                </TableRow>
                                

                            </TableBody>
                        </Table>
                    </TableContainer>

                </Paper>
            </div> {/* table behandlinger */}
        </>
    )
}

export default ApproveDoctors