import { useOutletContext } from "react-router-dom";
import SetPatientInformation from "../components/Patient/setPatientInformation";
import MainPatientPage from "../components/Patient/mainPatientPage";

const PatientPage = () => {

const [user, userDb] = useOutletContext();

  return (
   <>
    <SetPatientInformation />
    <MainPatientPage />
   </>
  )

}

export default PatientPage