import * as React from 'react';
import Button from '@mui/material/Button';
import CTAImage from '../../assets/cta-image.png';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

export default function Cta() {
  return (
    <>
        <div className="cta">
            <div className="container">
                <div className="inner-cta">
                    <img src={CTAImage} />
                    <h3>Legetime hvor som helst. Når som helst.</h3>
                    <p>
                    Med WifiSkop kan du som pasient få legetime hvor som helst, når som helst av våre virtuelle leger
                    </p>
                    <Button size="large" variant="contained">
                        <ThumbUpIcon />
                        BESTILL TIME NÅ
                    </Button>
                </div>
            </div>
        </div>
    </>
  );
}