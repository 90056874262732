import { useOutletContext } from "react-router-dom";
import VideoChat from "../videoChat/provider";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

const MainDoctorPage = () => { 

    const [user, setUser, userDb, setUserDb, meetingInfo, setMeetingInfo] = useOutletContext();

    if(user && userDb?.type === "Doctor" && userDb?.approvedDoctor === true) {
        return (
            <>
            {/*
                <div className="intro" style={{minHeight: 300}}>
                    <div className="container">
                        <h1>Hei, {userDb?.name} 👋</h1>
                        <img className="curve" src={Curve} />
                    </div>
                </div>
                {/* TODO:: Move functionality to super admin page */}
                <VideoChat type="doctor"/>
                
            </>
        )
    }

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
        </>
    )

}

export default MainDoctorPage