import { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";

const Statistic = () => { 

    const [user, setUser, userDb, setUserDb, meetingInfo, setMeetingInfo, setPatientsDb, patientsDb, patientsAll] = useOutletContext();
    const [patients, setPatients] = useState([])
    const [totalSum, setTotalSum] = useState()
    const [totalThisMonth, setTotalThisMonth] = useState()
    const [totalLastMonth, setTotalLastMonth] = useState()

    const d = new Date(); 
    let month = d.getMonth();

    useEffect(() => {
        patientsAll.forEach((doc) => {
            setPatients(patients => [...patients, doc.data()] );
        });
    }, [])


    useEffect(() => {
        if(patients) {
            const allPayed = patients?.filter(patient => patient.vippsStatus === "Captured")
            const sum = allPayed?.reduce((a, b) => parseInt(a) + parseInt(b.amountPayed), 0)
            setTotalSum(sum)
        }
    }, [patients])

    useEffect(() => {
        if(patients) {
            const allPayed = patients?.filter(patient => patient.vippsStatus === "Captured" && patients[0]?.timePayed?.toDate()?.getMonth() === month)
            const sum = allPayed?.reduce((a, b) => parseInt(a) + parseInt(b.amountPayed), 0)
            setTotalThisMonth(sum)
        }
    }, [patients])


    useEffect(() => {
        if(patients) {
            const allPayed = patients?.filter(patient => patient.vippsStatus === "Captured" && patients[0]?.timePayed?.toDate()?.getMonth() -1)
            const sum = allPayed?.reduce((a, b) => parseInt(a) + parseInt(b.amountPayed), 0)
            setTotalLastMonth(sum)
        }
    }, [patients])
   



    return (
        <>
            <div className="income">
                <h2>Min side</h2>
                <div className="income-cards">
                    <div class="card">
                        <h2>kr {totalThisMonth}</h2>
                        <p>Denne måned</p>
                    </div>
                    <div class="card">
                        <h2>kr {totalLastMonth}</h2>
                        <p>Forrige måned</p> 
                    </div>
                    <div class="card">
                        <h2>kr {totalSum}</h2>
                        <p>Totalt</p>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Statistic