import {useState, useEffect} from "react";
import { db } from "../../firebase";
import { useOutletContext } from "react-router-dom";
import { collection, query, where, doc, updateDoc, setDoc, addDoc, onSnapshot, serverTimestamp } from "firebase/firestore";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ActivePatients from "./activePatients";
import TextField from '@mui/material/TextField';
import Lightbox from './lightbox';
import FsLightbox from 'fslightbox-react';

const WaitingRoom = (props) => {

    const [toggler, setToggler] = useState(false);
    const [modalIndex, setModalIndex] = useState();
    const [patientList, setPatientList] = useState([]);
    const [open, setOpen] = useState(false);

    const [lightboxOpen, setLightboxOpen] = useState(false);

    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('md');
    const [user, setUser, userDb, setUserDb, meetingInfo, setMeetingInfo] = useOutletContext();

    const demoArr = [
        'one',
        'two',
        'three'
    ]

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpenImg = () => {
        setOpen(true);
    };

    const handleCloseImg = () => {
        setOpen(false);
    };

    const handleMaxWidthChange = (event) => {
        setMaxWidth(
        // @ts-expect-error autofill of arbitrary value is not handled.
        event.target.value,
        );
    };

    // TODO legg inn liste med pasienter som skal aksepteres 
    // TODO Skriv om komponentene, kompinentene burde ikke ligge her inne alene

        useEffect(() => {
            const q = query(collection(db, "Patients"), where("active", "==", true), where("payed", "==", true), where("gettingServed", "==", false));
            // Quer to use if you want to keep patients in list, even if they are gettingServed.
            //const q = query(collection(db, "Patients"), where("active", "==", true));

            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const data = querySnapshot.docs.map(doc => doc);
       
                setPatientList(data);
            });
            return () => unsubscribe();
        }, [])


        const sendSMSToPatient = async (patientName, patientNumber) => {
            const message = `hei ${patientName}, en lege venter nå på deg. Besøk https://localhost:3000 for å starte konsultasjonen`;
            await addDoc(collection(db, "messages"), { 
                to: `+47${patientNumber}`,
                body: message,  
              }).then((result) => {
                console.log("SMS sendt")
              }).catch((error) => { console.log("SMS sendt failed: " + error.message) });
        }

        const setAvtiveTreatmentSeassion = async (treatmentId, patientName, patientNumber) => {
            const userRef = doc(db, "Patients", treatmentId);
            updateDoc(userRef, {
                treatedById: userDb?.id,
                gettingServed: true,
                treatmentStarted: serverTimestamp(),
            }).then(async (result) => {
                sendSMSToPatient(patientName, patientNumber);
            }).catch((err) => {
                console.log("Error på DB update ::: ", err)
            });
        }

    return (
        <>
        <ActivePatients />

        <div className="table-behandlinger">
            <h2>Tilgjengelige pasienter</h2>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 350 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Navn</TableCell>
                                <TableCell>Start behandling</TableCell>
                                <TableCell>&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {patientList?.map((data, index) => {     
                                console.log("dette er masssse data", data.data());               
                                return (
                                    <>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell>{data.data().name}</TableCell>
                                            <TableCell>
                                                <Button
                                                    sx={{ color: 'white', borderRadius: '24px', fontSize: '11px', backgroundColor: '#DD0A01', "&:hover": {
                                                        backgroundColor: '#DD0A01'
                                                    } }}
                                                    size="small"
                                                    onClick={() => {
                                                        setModalIndex(index)
                                                        setOpen(!open)
                                                    } }
                                                    variant="contained"
                                                >
                                                    SE INFORMASJON
                                                </Button>
                                            </TableCell>
                                            <TableCell>&nbsp;</TableCell>
                                        </TableRow>
                                    </>
                                )
                            })}
                            <Dialog
                                fullWidth={fullWidth}
                                maxWidth={maxWidth}
                                open={open}
                                onClose={handleClose}
                            > 
                            

                                <div className="dialog-holder">
                                    <h2>{patientList[modalIndex]?.data()?.name} ({patientList[modalIndex]?.data()?.ssn})</h2>
                                    <p>
                                        Her er det litt informasjon om pasienten, slik at du
                                        kan hjelpe pasienten bedre
                                    </p>
                                    <div className="border-r"></div>
                                    <form className="inner-dialog-holder">
                                        <div className="upper">
                                        <Box
                                            component="form"
                                            sx={{'& > :not(style)': { m: 1, width: '25ch', mb: 4 },}}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <FormControl>
                                                <InputLabel id="demo-simple-select-label">Opplever du smerter?</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="Opplever du smerter?"
                                                    defaultValue={patientList[modalIndex]?.data()?.pains}
                                                    disabled
                                                
                                                >
                                                    <MenuItem value="Ja">Ja</MenuItem>
                                                    <MenuItem value="Nei">Nei</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <Box
                                            component="form"
                                            sx={{'& > :not(style)': { m: 1, width: '25ch', mb: 4 },}}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <FormControl>
                                                <InputLabel id="demo-simple-select-label">Trenger du sykemelding eller legeerklering?</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="Trenger du sykemelding eller legeerklering?"
                                                    defaultValue={patientList[modalIndex]?.data()?.sickLeave}
                                                    disabled
                                                >
                                                    <MenuItem value="Ja">Ja</MenuItem>
                                                    <MenuItem value="Nei">Nei</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>

                                            <Box
                                                component="form"
                                                sx={{'& > :not(style)': { m: 1, width: '25ch',  mb: 4 },}}
                                                noValidate
                                                autoComplete="off"
                                            >
                                                <FormControl>
                                                    <InputLabel id="demo-simple-select-label">Har du en infeksjon?</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="Har du en infeksjon?"
                                                        defaultValue={patientList[modalIndex]?.data()?.infection}
                                                        disabled
                                                    >
                                                        <MenuItem value="Ja">Ja</MenuItem>
                                                        <MenuItem value="Nei">Nei</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                    
                                            <Box
                                                component="form"
                                                sx={{'& > :not(style)': { m: 1, width: '25ch',  mb: 4 },}}
                                                noValidate
                                                autoComplete="off"
                                            >
                                                <FormControl>
                                                    <InputLabel id="demo-simple-select-label">Trenger du en resept eller resept fornyelse?</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="Trenger du en resept eller resept fornyelse?"
                                                        defaultValue={patientList[modalIndex]?.data()?.prescription}
                                                        disabled
                                                    >
                                                        <MenuItem value="Ja">Ja</MenuItem>
                                                        <MenuItem value="Nei">Nei</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            </div> {/* upper */}
                                            {/*<div className="images-from-patient">
                                                <h4>Bilder som pasienten har lastet opp:</h4>
                                                <div className="image-holder">
                                                    {patientList[modalIndex]?.data()?.images &&
                                                    patientList[modalIndex]?.data()?.images?.map((url) => {
                                                            return (
                                                                <img src={url} alt='uploaded file' height={130} width={130} />
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <a href="#" onClick={() => setToggler(!toggler)}>
                                                    Se bilder
                                                </a>

                                                <FsLightbox
                                                    type='image'
                                                    toggler={toggler}
                                                    sources={patientList[modalIndex]?.data()?.images}
                                                />
                                                

                                            </div> */}
                                            
                                            <div className="patient-information-box">
                                                <Box
                                                    component="form"
                                                    sx={{'& > :not(style)': { width: '100%' },}}
                                                    noValidate
                                                    autoComplete="off"
                                                >
                                                    <TextField
                                                        multiline
                                                        label="Litt om pasienten sin situasjon"
                                                        rows={4}
                                                        maxRows={8}
                                                        placeholder="Her er det litt informasjon om pasient"
                                                        disabled
                                                    />
                                                </Box>
                                            </div>
                                            <div className="under">
                                                <div className="btn-holder">
                                                    <Button
                                                        
                                                        sx={{mt: 5, color: 'white', borderRadius: '25px', backgroundColor: '#DD0A01', "&:hover": {
                                                            backgroundColor: '#DD0A01'
                                                        } }}
                                                        size="large"
                                                        variant="contained"
                                                        onClick={async (event) => {
                                                            setOpen(false);
                                                            setAvtiveTreatmentSeassion(patientList[modalIndex].id, patientList[modalIndex]?.data()?.name, patientList[modalIndex]?.data()?.mobile)
                                                            props.setJoinRoom({type: "provider", roomName: patientList[modalIndex]?.data()?.roomName, event: event, patientInfo: patientList[modalIndex]})
                                                        }} 
                                                    >
                                                        START BEHANDLING
                                                        <ArrowForwardIcon sx={{ml: 1}} />
                                                    </Button>
                                                </div>
                                            </div> {/* under */}
                                            
                                    </form> {/* inner-dialog-holder */}
                                    
                                </div> {/* dialog-holder */}
                                
                            </Dialog>     
                        </TableBody>
                    </Table>
                </TableContainer>

        </div> {/* table behandlinger */}
        </>
    )
}

export default WaitingRoom